@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=JetBrains+Mono:wght@400;500;600;700&display=swap');

:root {
  /* Colors */
  --primary: #4200FF;
  --primary-light: #5F3CFF;
  --primary-dark: #2E00D6;
  --secondary: #f9f9f9;
  --neutral00: ##FFFFFF;
  --neutral05: #F4F4F4;
  --neutral10: #E9E9E9;
  --neutral20: #D9D9D9;
  --neutral30: #BFBFBF;
  --neutral40: #A6A6A6;
  --neutral50: #8C8C8C;
  --neutral60: #737373;
  --neutral70: #595959;
  --neutral80: #404040;
  --neutral90: #262626;
  --neutral100: #0D0D0D;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
